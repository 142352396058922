<template>
	<div :id="mainNavIsHidden ? 'main-nav-hidden-transaction-table' : 'transactions-container'">
		<table class="transactions encore-background curved-border" v-if="transactions.length > 0">
			<table class="transactions second-table-container">
				<thead>
					<tr class="header-row">
						<!-- <th>{{ languageStrings.casinoId }}</th> -->
						<th>{{ languageStrings.fromPlayerCash }}</th>
						<th>{{ languageStrings.initiationDate }}</th>
					</tr>
				</thead>
				<tbody
					v-for="(item, index) in transactions"
					:key="index"
					@click="setOpenClass(index)"
					:id="`row-${index}`"
				>
					<tr class="accordion data-row curved-border">
						<!-- <td>{{ item.casinoId }}</td> -->
						<td v-if="item.fromPlayerCashCOC >= 0">
							{{
								systemCurrencyTool.formatCurrency(
									item.fromPlayerCashCOC,
									systemCurrencyTool.displayType.minorOrFull
								)
							}}
						</td>
						<td v-else class="negative-value">
							{{
								systemCurrencyTool.formatCurrency(
									item.fromPlayerCashCOC,
									systemCurrencyTool.displayType.minorOrFull
								)
							}}
						</td>
						<td v-if="item.initiationDate">
							{{ new Date(item.initiationDate).toLocaleTimeString(this.localeString, dateOptions) }}
						</td>
						<td v-else></td>
					</tr>
					<tr class="panel">
						<td colspan="2">
							<table class="expandable-data-table">
								<thead>
									<th>{{ languageStrings.status }}</th>
									<th>{{ languageStrings.offerExpirationDate }}</th>
									<th>{{ languageStrings.provider }}</th>
									<th>{{ languageStrings.statusAdditionalInfo }}</th>
								</thead>
								<tr class="position-expandable-data">
									<td>{{ item.status }}</td>
									<td v-if="item.offerExpirationDate">
										{{
											new Date(item.offerExpirationDate).toLocaleTimeString(
												this.localeString,
												dateOptions
											)
										}}
									</td>
									<td v-else></td>
									<td>{{ item.provider }}</td>
									<td>{{ item.statusAdditionalInfo }}</td>
								</tr>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
		</table>
		<h2 v-else style="color: black">{{ languageStrings.noTransactionsFound }}</h2>
	</div>
</template>

<script>
export default {
	name: "TransactionsTable",
	props: {
		systemCurrencyTool: Object,
		systemSettings: Object,
		transactions: Array,
		languageStrings: Object,
		mainNavIsHidden: Boolean,
	},
	data() {
		return {
			// expandedRows: []
		};
	},
	methods: {
		setOpenClass(index) {
			var currentRow = document.getElementById(`row-${index}`);
			currentRow.classList.contains("open")
				? currentRow.classList.remove("open")
				: currentRow.classList.add("open");
		},

		// checkForExpandedRows(index) {
		// 	return this.expandedRows.includes(index);
		// }
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2,
h3 {
	text-align: center;
}

#transactions-container {
	display: grid;
	/* margin: auto; */
	/* width: 80%; */
	/* background-color: #d7d7d7; */
	/* border-width: 0 1px 1px 1px;
	border-style: solid;
	box-sizing: border-box; */
}

.transactions {
	position: relative;
	width: 95%;
	margin: auto;
	border-collapse: separate;
	text-align: center;
	/* background-color: #d7d7d7; */
	/* box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%); */
	border-spacing: 0 0.7em;
}

.transactions tr {
	transition: background-color 0.3s;
}

tr.header-row {
	position: sticky;
	top: 0;
	background-color: #6305c9;
	color: white;
	border-bottom: 1px solid rgb(123, 123, 123);
	white-space: break-spaces;
	line-height: 4vh;
	font-size: large;
}

.transactions tr.data-row:hover,
.transactions tr:nth-child(2n):hover {
	background-color: #222;
	color: #fff;
}
.transactions tbody {
	background-color: white;
	color: #6305c9;
}

.data-row {
	height: 4vh;
}

td::first-letter {
	text-transform: capitalize;
}

tr td:first-of-type {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	margin-left: 10px;
}

tr td:last-of-type {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

tr {
	padding: 10px;
	font-weight: bold;
}

.panel {
	display: none;
}

.open .panel {
	display: table-row;
}

.panel {
	height: 10vh;
}

.expandable-data-table {
	width: 100%;
	color: white;
}

.second-table-container {
	width: 96%;
}

.position-expandable-data {
	line-height: 5vh;
}

#main-nav-hidden-transaction-table {
	margin: 0;
	top: 2vh;
	position: relative;
}
</style>
